const { clearAllBodyScrollLocks } = require('body-scroll-lock');
const React = require('react');
const Layout = require('src/components/Layout').default;

exports.wrapPageElement = ({ element, props }) => {
	return <Layout {...props}>{element}</Layout>;
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
	if (location.pathname !== prevLocation?.pathname) clearAllBodyScrollLocks();
};
