import languageData from 'src/languages';

// There are some inconsistencies between SpectroCoin landing locales and our project
// So this helper function helps to convert from our project locale to SC landing locale
const fromUItoSpectroCoinLanding = (uiLocale: string): string => {
	// Make sure this list does not go out of date (if you add/remove languages or something in SC landing locales changes)
	const unchangedLocales = [
		languageData.Default,
		languageData.Arabic,
		languageData.PortugueseEuropean,
		languageData.PortugueseBrazilian,
		languageData.ChineseMainland,
		languageData.ChineseTaiwan,
		languageData.Spanish,
	];

	const matching = unchangedLocales.find((language) => language?.locale === uiLocale);
	if (matching) return uiLocale;
	return uiLocale.slice(0, 2);
};
export default fromUItoSpectroCoinLanding;
