import React from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import Footer from 'src/components/footer/Footer';
import getLocaleDataFromString from 'src/helpers/getLocaleDataFromString';
import languageData from 'src/languages';
import 'src/styles/main.scss';

interface Props {
	location: { pathname: string };
}

const Layout: React.FC<Props> = ({ children, location }) => {
	const { pathname } = location;
	const localeData = getLocaleDataFromString(pathname);

	return (
		<IntlProvider
			messages={localeData.messages}
			locale={localeData.locale}
			defaultLocale={languageData.Default.locale}
		>
			<Helmet>
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
				<link
					href="https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"
					rel="stylesheet"
				/>
				<link
					href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap"
					rel="stylesheet"
				/>
				<html dir={localeData === languageData.Arabic ? 'RTL' : 'LTR'} lang={localeData.locale} />
			</Helmet>
			<div className="layoutWrapper">
				<div className="layout">
					{children}
					<Footer />
				</div>
			</div>
		</IntlProvider>
	);
};

export default Layout;
