import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import spectrocoinLogo from 'src/assets/images/vector/spectrocoin-logo.svg';
import socials from 'src/helpers/socials';
import fromUItoSpectroCoinLanding from 'src/helpers/spectroCoinLandingLocales';
import * as style from './Footer.module.scss';

const Footer: React.FC = () => {
	const { locale } = useIntl();
	return (
		<div className={style.container}>
			<div className={style.firstRow}>
				<div className={style.linksContainer}>
					<div className={style.followUs}>
						<FormattedMessage id="footer.follow" defaultMessage="Follow us on social media" />
					</div>
					<div className={style.iconsContainer}>
						{socials.map((social) => (
							<div className={style.iconContainer} key={social.image}>
								<a href={social.link} target="_blank" rel="noopener noreferrer">
									<FormattedMessage {...social.alt}>
										{(alt: string) => (
											<img src={social.image} alt={alt} className={style.socialIcon} />
										)}
									</FormattedMessage>
								</a>
							</div>
						))}
					</div>
				</div>
				<div className={style.logoContainer}>
					<a href={`https://www.spectrocoin.com/${fromUItoSpectroCoinLanding(locale)}`}>
						<FormattedMessage id="alt.SpectroCoinLogo" defaultMessage="SpectroCoin logo">
							{(alt: string) => <img src={spectrocoinLogo} alt={alt} className={style.logo} />}
						</FormattedMessage>
					</a>
				</div>
			</div>
			<div className={style.copyright}>
				<FormattedMessage
					id="footer.copyright"
					defaultMessage="Copyright © 2013-2022 Spectro Finance OÜ. All rights reserved"
					values={{year:new Date().getFullYear()}}
				/>
			</div>
		</div>
	);
};

export default Footer;
