import { defineMessages } from 'react-intl';
import facebookLogo from 'src/assets/images/vector/socialIcons/facebook.svg';
import facebookWhiteLogo from 'src/assets/images/vector/socialIcons/facebook-white.svg';
import linkedinLogo from 'src/assets/images/vector/socialIcons/linkedin.svg';
import linkedinWhiteLogo from 'src/assets/images/vector/socialIcons/linkedin-white.svg';
import twitterLogo from 'src/assets/images/vector/socialIcons/twitter.svg';
import twitterWhiteLogo from 'src/assets/images/vector/socialIcons/twitter-white.svg';
import instagramLogo from 'src/assets/images/vector/socialIcons/instagram.svg';
import youtubeLogo from 'src/assets/images/vector/socialIcons/youtube.svg';

const translations = defineMessages({
	facebook: {
		id: 'alt.facebook',
		defaultMessage: 'Facebook logo',
	},
	linkedin: {
		id: 'alt.linkedin',
		defaultMessage: 'Linkedin logo',
	},
	twitter: {
		id: 'alt.twitter',
		defaultMessage: 'Twitter logo',
	},
	instagram: {
		id: 'alt.instagram',
		defaultMessage: 'Instagram logo',
	},
	youtube: {
		id: 'alt.youtube',
		defaultMessage: 'Youtube logo',
	},
});

export interface Social {
	name: string;
	link: string;
	image: string;
	imageWhite?: string;
	alt: { id: string; defaultMessage: string };
	tag: string;
}

const socials: Social[] = [
	{
		name: 'facebook',
		link: 'https://www.facebook.com/spectrocoin',
		image: facebookLogo,
		imageWhite: facebookWhiteLogo,
		alt: translations.facebook,
		tag: 'SpectroCoin',
	},
	{
		name: 'linkedin',
		link: 'https://linkedin.com/company/spectrocoin',
		image: linkedinLogo,
		imageWhite: linkedinWhiteLogo,
		alt: translations.linkedin,
		tag: '@SpectroCoin',
	},
	{
		name: 'twitter',
		link: 'https://twitter.com/spectrocoin',
		image: twitterLogo,
		imageWhite: twitterWhiteLogo,
		alt: translations.twitter,
		tag: '@SpectroCoin',
	},
	{
		name: 'instagram',
		link: 'https://www.instagram.com/spectrocoin/',
		image: instagramLogo,
		alt: translations.instagram,
		tag: 'spectrocoin',
	},
	{
		name: 'youtube',
		link: 'https://www.youtube.com/channel/UC5cWUFTcU7_vWwX1dX2to_Q',
		image: youtubeLogo,
		alt: translations.youtube,
		tag: 'SpectroCoin',
	},
];

export default socials;
