import enMessages from './en.json';
// import trMessages from './tr-TR.json';
import etEEMessages from './et-EE.json';
import zhCNMessages from './zh-CN.json';
import zhTWMessages from './zh-TW.json';
import ltLTMessages from './lt-LT.json';
import arMessages from './ar.json';
import ptPTMessages from './pt-PT.json';
import ptBRMessages from './pt-BR.json';
import frFRMessages from './fr-FR.json';
import nlNLMessages from './nl-NL.json';
import itITMessages from './it-IT.json';
import jaMessages from './ja.json';
import koKRMessages from './ko-KR.json';
import esESMessages from './es-ES.json';
import lvLVMessages from './lv-LV.json';
import plPLMessages from './pl-PL.json';
import ruRUMessages from './ru-RU.json';

export interface Languages<T> {
	[key: string]: T | undefined;
	Default: T; // Default language is not optional

	// Add more optional languages to the list if you need to
	// Turkish?: T; // Removed temporarily until we have a Turkish translator
	Estonian?: T;
	ChineseMainland?: T;
	ChineseTaiwan?: T;
	Lithuanian?: T;
	Arabic?: T;
	PortugueseEuropean?: T;
	PortugueseBrazilian?: T;
	French?: T;
	Dutch?: T;
	Italian?: T;
	Japanese?: T;
	Korean?: T;
	Spanish?: T;
	Latvian?: T;
	Polish?: T;
	Russian?: T;
}

export interface LocaleData {
	locale: string;
	title: string;
	shortTitle: string;
	messages: Record<string, string>;
}

const languageData: Languages<LocaleData> = {
	Default: { locale: 'en', title: 'English', shortTitle: 'en', messages: enMessages },
	// Turkish: { locale: 'tr-TR', title: 'Türkçe', shortTitle: 'tr', messages: trMessages }
	Estonian: { locale: 'et-EE', title: 'Eesti', shortTitle: 'et-EE', messages: etEEMessages },
	// ChineseMainland: { locale: 'zh-CN', title: '中文 (中国)', shortTitle: 'zh-CN', messages: zhCNMessages },
	// ChineseTaiwan: { locale: 'zh-TW', title: '中文 (台灣)', shortTitle: 'zh-TW', messages: zhTWMessages },
	Lithuanian: { locale: 'lt-LT', title: 'Lietuvių', shortTitle: 'lt', messages: ltLTMessages },
	Arabic: { locale: 'ar', title: 'اَلْعَرَبِيَّةُ', shortTitle: 'ar', messages: arMessages },
	// PortugueseEuropean: { locale: 'pt-PT', shortTitle: 'pt-pt', title: 'Português (PT)', messages: ptPTMessages },
	// PortugueseBrazilian: { locale: 'pt-BR', shortTitle: 'pt-br', title: 'Português (BR)', messages: ptBRMessages },
	French: { locale: 'fr-FR', title: 'Français', shortTitle: 'fr', messages: frFRMessages },
	// Dutch: { locale: 'nl-NL', title: 'Nederlands', shortTitle: 'nl', messages: nlNLMessages },
	Italian: { locale: 'it-IT', title: 'Italiano', shortTitle: 'it', messages: itITMessages },
	// Japanese: { locale: 'ja', title: '日本語', shortTitle: 'ja', messages: jaMessages },
	// Korean: { locale: 'ko-KR', title: '한국어', shortTitle: 'ko', messages: koKRMessages },
	Spanish: { locale: 'es-ES', title: 'Español', shortTitle: 'es', messages: esESMessages },
	// Latvian: { locale: 'lv-LV', title: 'Latviešu', shortTitle: 'lv', messages: lvLVMessages },
	// Polish: { locale: 'pl-PL', title: 'Polski', shortTitle: 'pl', messages: plPLMessages },
	Russian: { locale: 'ru-RU', title: 'Русский', shortTitle: 'ru', messages: ruRUMessages },

	// Add more language data to the list if you need to
};

export default languageData;
