// extracted by mini-css-extract-plugin
export var container = "Footer-module--container--1cVSm";
export var copyright = "Footer-module--copyright--S58OT";
export var firstRow = "Footer-module--firstRow--Ow8Ft";
export var followUs = "Footer-module--followUs--oluiV";
export var iconContainer = "Footer-module--iconContainer--b6duO";
export var iconsContainer = "Footer-module--iconsContainer--jycQG";
export var linksContainer = "Footer-module--linksContainer--tN48a";
export var logo = "Footer-module--logo--ocsj6";
export var logoContainer = "Footer-module--logoContainer--aHg8m";
export var socialIcon = "Footer-module--socialIcon--Jnopq";